// 习题库
import { Layout, NoLayout } from '../constant-components.js';

export default {
  path: '/paper',
  redirect: '',
  component: Layout,
  meta: {
    title: ''
  },
  children: [
    {
      path: 'index',
      component: NoLayout,
      meta: {
        title: '',
        associateMenu: 'paper/index'
      },
      children: [
        {
          path: 'list',
          component: () => import('@/views/paper/index/list/list.vue'),
          meta: {
            title: ''
          },
        },
        {
          path: 'exercises-add',
          component: () => import('@/views/paper/index/exercises-add/exercises-add.vue'),
          meta: {
            title: '从习题库组卷'
          },
        },
        {
          path: 'exercises-edit',
          component: () => import('@/views/paper/index/exercises-add/exercises-add.vue'),
          meta: {
            title: '从习题库组卷'
          },
        },
        {
          path: 'manual-add',
          component: () => import('@/views/paper/index/manual-add/manual-add.vue'),
          meta: {
            // title: '手动组卷'
            title: '新增试卷'
          },
        },
        {
          path: 'manual-edit',
          component: () => import('@/views/paper/index/manual-add/manual-add.vue'),
          meta: {
            // title: '手动组卷'
            title: '编辑试卷'
          },
        },
      ]
    }
  ]
};