import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

// 学校列表
export function getSchoolList(data) {
  return request({
    url: '/backend/web/school/index',
    method: GET,
    params: data
  });
}

// 学校详情
export function GetSchoolDetail(data) {
  return request({
    url: '/backend/web/school/detail',
    method: GET,
    params: data
  });
}

// 学校编辑
/*
{
  'School[id]': string;
  'School[system_name]': string;
  'School[province]': string;
  'School[city]': string;
  'School[district]': string; // 区ID
  'School[address]': string;
  'School[badge]': string;
  'School[background]': string;
  'School[school_type_id]': string;
  'School[customized_filings_info]': string;
  'School[customized_logo]': string;
  'School[customized_illustrate]': string;
  'School[customized_login_page_type]': number;
}
*/
export function PostSchoolUpdate(data) {
  return request({
    url: '/backend/web/school/update',
    method: POST,
    data
  });
}


// 学校存储空间设置
export function PostSchoolUpdateStorage(data) {
  return request({
    url: '/backend/web/school/update-storage',
    method: POST,
    data
  });
}

// 获取地区数据集
export function PostUploadRegion(data) {
  return request({
    url: '/backend/web/upload/region',
    method: POST,
    data
  });
}

// 学校分发
export function PostDistribute(data) {
  return request({
    url: '/backend/web/school/distribute',
    method: POST,
    data
  });
}

// 学校类型(列表)
export function PostWebSchooTtypeIndex(data) {
  return request({
    url: '/backend/web/school-type/index',
    method: GET,
    params: data
  });
}

// 学校类型(新增)
export function PostWebSchooTtypeCreate(data) {
  return request({
    url: '/backend/web/school-type/create',
    method: POST,
    data
  });
}

// 操作日志
export function PostWebSchoolOperation(data) {
  return request({
    url: '/backend/web/school/operation-log',
    method: GET,
    params: data
  });
}

// 已分发操作
export function PostSchoolDistributeLog(data) {
  return request({
    url: '/backend/web/school/distribute-log',
    method: POST,
    data
  });
}

// 已分发操作列表
export function GetSchoolDistributeLog(data) {
  return request({
    url: '/backend/web/school/distribute-log',
    method: GET,
    params: data
  });
}

export function reqSchoolEmail(data) {
  return request({
    url: '/backend/web/school/school-email',
    method: POST,
    data
  });
}

export function setSchoolIPMonitor(data) {
  return request({
    url: '/backend/web/school/school-ip',
    method: POST,
    data
  });
}

export function reqLoginRecords(data) {
  return request({
    url: '/backend/web/school/monitor-log',
    method: GET,
    params: data
  });
}

export function delLoginRecords(data) {
  return request({
    url: '/backend/web/school/monitor-log-delete',
    method: POST,
    data
  });
}

export function resetLoginRecords(data) {
  return request({
    url: '/backend/web/school/monitor-log-reset',
    method: POST,
    data
  });
}

/*
{
  'SchoolCustomInfo[school_id]': string;
}
*/
export function customSchoolInfo(params) {
  return request({
    url: '/backend/web/school-custom/detail',
    method: GET,
    params
  });
}

/*
{
  'SchoolCustomInfo[title]': string;
  'SchoolCustomInfo[system_domain]': string;
  'SchoolCustomInfo[fillings_info]': string;
  'SchoolCustomInfo[logo]': string;
  'SchoolCustomInfo[illustrate]': string;
  'SchoolCustomInfo[login_page_type]': number;
  'SchoolCustomInfo[school_id]': string;
}
*/
export function customSchoolCreate(data) {
  return request({
    url: '/backend/web/school-custom/create',
    method: POST,
    data
  });
}