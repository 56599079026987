import { resStatusEnum } from '@/enumerators/http.js';

import { productCategoryList } from '@/api/product';

const { SUCCESS } = resStatusEnum;

const state = {
  lv1List: [],
  lv1IDLv2list: {}
};

const mutations = {
  updateList(state, { pid, list }) {
    if (pid === '0') {
      state.lv1List = list;
    } else {
      state.lv1IDLv2list[pid] = list;
    }
  }
};

const actions = {
  getProductCategoryList({ state, commit }, pid) {
    return new Promise((resolve, reject) => {
      if (pid === '0' && state.lv1List.length > 0) {
        resolve();
      } else if (pid !== '0' && state.lv1IDLv2list[pid]) {
        resolve();
      } else {
        productCategoryList({ pid, page: 1, page_size: 99999 }).then(({ code, data, msg }) => {
          if (code === SUCCESS) {
            commit('updateList', { pid, list: Array.isArray(data.list) ? data.list : [] });
            resolve();
          } else {
            reject(msg);
          }
        }).catch(reject);
      }
    });
  },
  updateProductCategoryList({ commit }, pid) {
    return new Promise((resolve, reject) => {
      productCategoryList({ pid, page: 1, page_size: 99999 }).then(({ code, data, msg }) => {
        if (code === SUCCESS) {
          commit('updateList', { pid, list: Array.isArray(data.list) ? data.list : [] });
          resolve();
        } else {
          reject(msg);
        }
      }).catch(reject);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};