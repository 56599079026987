// 课程管理
import { Layout, NoLayout } from '../constant-components.js';

export default {
  path: '/teaching-system',
  redirect: '',
  component: Layout,
  meta: {
    title: ''
  },
  children: [
    {
      path: 'index',
      component: NoLayout,
      meta: {
        title: '教学系统',
        associateMenu: 'teaching-system/index'
      },
      children: [
        {
          path: 'index',
          component: () => import('@/views/teaching-system/list/list.vue'),
          meta: {
            title: ''
          }
        },
        {
          path: 'build',
          component: () => import('@/views/teaching-system/build/build.vue'),
          meta: {
            title: '系统建设'
          },
        },
        /* {
          path: 'details',
          component: () => import('@/views/teaching-system/details.vue'),
          meta: {
            title: '详情'
          },
        }, */
        {
          path: 'article-list',
          component: () => import('@/views/course-system/article-list/article-list.vue'),
          meta: {
            title: '互动公告'
          },
        }
      ]
    }
  ]
};