// 课程管理
import { Layout, NoLayout } from '../constant-components.js';

export default {
  path: '/course',
  redirect: '',
  component: Layout,
  meta: {
    title: ''
  },
 
children: [
  {
    path: 'index',
    component: NoLayout,
    meta: {
      title: '课程系统',
      associateMenu: 'course/index'
    },
    children: [
      {
        path: 'index',
        // component: () => import('@/views/course/list/list.vue'),
        component: () => import('@/views/course-system/list/list.vue'),
        meta: {
          title: ''
        }
      },
      {
        path: 'build',
        // component: () => import('@/views/course/build/build.vue'),
        component: () => import('@/views/course-system/build/build.vue'),
        meta: {
          // title: '课程建设'
          title: '系统建设'
        },
      },
      {
        path: 'details',
        component: () => import('@/views/course/details.vue'),
        meta: {
          title: '详情'
        },
      },
      {
        path: 'article-list',
        component: () => import('@/views/course-system/article-list/article-list.vue'),
        meta: {
          title: '互动公告'
        },
      },

      
      {
        path: 'teaching-system-build',
        component: () => import('@/views/course/teaching-system-build/teaching-system-build.vue'),
        meta: {
          title: '教学系统建设'
        }
      }
    ]
  }
]
    
 
};