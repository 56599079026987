// 实训管理
import { Layout, NoLayout } from '../constant-components.js';

export default {
  path: '/task',
  redirect: '',
  component: Layout,
  meta: {
    title: ''
  },
 
children: [
  {
    path: 'index',
    component: NoLayout,
    meta: {
      title: '实训列表',
      associateMenu: 'task/index'
    },
    children: [
      {
        path: 'index',
        // component: () => import('@/views/task/index.vue'),
        component: () => import('@/views/training-system/list/list.vue'),
        meta: {
          title: ''
        }
      },
      {
        path: 'article-list',
        component: () => import('@/views/course-system/article-list/article-list.vue'),
        meta: {
          title: '互动公告'
        }
      },
      {
        path: 'build',
        component: () => import('@/views/training-system/build/build.vue'),
        meta: {
          title: '系统建设'
        }
      },
      {
        path: 'addedit',
        component: () => import('@/views/task/build/build.vue'),
        meta: {
          title: '实训编辑'
        },
      }
    ]
  }
]
    
 
};