import { Layout } from '../constant-components.js';

export default {
  path: '/system-category',
  redirect: '',
  component: Layout,
  meta: {
    title: '',
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/system-category/list/list.vue'),
      meta: {
        title: '系统分类管理'
      }
    }
  ]
};