import { resetRouter } from '@/router/index.js';
import { getMenus } from '@/api/permission.js';
import { resStatusEnum } from '@/enumerators/http.js';
import { login, logout } from '@/api/permission.js';
import { changeTokenLocalStorage, setToken, removeToken } from '@/utils/auth.js';
import {
  formTempRouteMap,
  generateAccessAsyncRoutes,
  addRouteRedirect,
  redirectMenuPath
} from '@/router/async-routes.js';
const { SUCCESS } = resStatusEnum;

// 菜单排序
function sortApiMenus(menus) {
  if (Array.isArray(menus)) {
    menus.forEach(menu => {
      sortApiMenus(menu.children);
    });
    menus.sort((a, b) => a.iorder - b.iorder);
  }
}

// 调整菜单，提取有用数据
/*
[{
  title: '',
  link: '',
  icon: '',
  children: []
}]
*/
function filterMenus(menus) {
  const correctedMenus = [];
  if (Array.isArray(menus)) {
    menus.forEach(menu => {
      const level = Number(menu.level);
      if ([1, 2].includes(level)) { // 1、2 级是页面
        const menuChildren = Array.isArray(menu.children) && menu.children.length ? menu.children : null;
        const tempObj = {
          title: menu.menu_name,
          link: menu.menu_url,
          icon: menu.file_url
        };
        if (menuChildren) {
          const children = filterMenus(menuChildren);
          children.length > 0 && (tempObj.children = children);
        }
        correctedMenus.push(tempObj);
      }
    });
  }
  return correctedMenus;
}

const _usernameKey = 'uu_a_un';

const state = {
  // asyncRoutes: [],
  menus: [], // link 前少 /
  permitURLs: [],
  username: window.localStorage.getItem(_usernameKey),
  videoURLDomain: process.env.VUE_APP_ALIYUN_VIDEO_DEFAULT_DOMAIN,
  fileURLDomain: 'https://sximg.uulian.com/'
};

const mutations = {
  /* updateAsyncRoutes(state, routes) {
    state.asyncRoutes = routes;
  }, */
  updateMenus(state, menus) {
    state.menus = menus;
  },
  updatePermitURLs(state, menus) {
    const urls = [];
    function updateUrls(list = menus) {
      if (Array.isArray(list)) {
        list.forEach(({ menu_url, children }) => {
          !!menu_url && urls.push(menu_url);
          updateUrls(children || []);
        });
      }
    };
    updateUrls();
    state.permitURLs = urls;
  },
  updateUsername(state, name) {
    state.username = name;
  },
  updateDomains(state, { file, video } = {}) {
    const reg = /^https?/;
    const regEnd = /\/$/;
    if (file) {
      reg.test(file) || (file = `https://${file}`);
      regEnd.test(file) || (file = `${file}/`);
      state.fileURLDomain = file;
    }
    if (video) {
      reg.test(video) || (video = `https://${video}`);
      regEnd.test(video) || (video = `${video}/`);
      state.videoURLDomain = video;
    }
  }
};

const actions = {
  login({ commit }, reqData) {
    return new Promise((resolve, reject) => {
      const { username, password, recordLogin } = reqData;
      login({
        'LoginForm[username]': username,
        'LoginForm[password]': password,
        'LoginForm[rememberMe]': recordLogin ? 1 : 0
      }).then(res => {
        if (res.code === SUCCESS) {
          changeTokenLocalStorage(recordLogin);
          setToken(res.data.token);
          window.localStorage.setItem(_usernameKey, res.username);
          commit('updateUsername', res.username);
          resolve();
        } else {
          reject(res.msg);
        }
      }).catch(reject);
    });
  },
  logout({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      dispatch('clearLoginInfo');
      resolve();
    });
  },
  clearLoginInfo({ commit }) {
    resetRouter();
    commit('updateMenus', []);
    // commit('updateAsyncRoutes', []);
    removeToken();
  },
  generateMenus({ commit }, menus) {
    commit('updateMenus', menus);
  },
  getAccessAsyncRoutes({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      getMenus({type:"1"}).then(res => {
        if (res.code === SUCCESS) {
          // commit('updateDomains', res.domain);

          let menus = res.data;
          commit('updatePermitURLs', menus);
          sortApiMenus(menus);
          menus = filterMenus(menus);
          formTempRouteMap(menus);
          const accessAsyncRoutes = generateAccessAsyncRoutes();
          addRouteRedirect(accessAsyncRoutes);
          redirectMenuPath(menus);
          dispatch('generateMenus', JSON.parse(JSON.stringify(menus)));
          resolve(accessAsyncRoutes);
        } else {
          reject(res.msg);
        }
      }).catch(reject);
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};