// 课程管理
import { Layout, NoLayout } from '../constant-components.js';

export default {
  path: '/home-banner',
  component: Layout,
  meta: {
    title: ''
  },
  children: [
    {
      path: 'index',
      component: NoLayout,
      meta: {
        title: '首页banner',
        associateMenu: 'home-banner/index'
      },
      children: [
        {
          path: 'index',
          component: () => import('@/views/home-banner/list/list.vue'),
          meta: {
            // title: '首页banner'
          }
        },
      ]
    }
  ]
};