import { resStatusEnum } from '@/enumerators/http.js';

import { PostUploadRegion } from '@/api/school.js';

const { SUCCESS } = resStatusEnum;

const state = {
  provinceListData: {},
  provinceIDCityListData: {}
};

const mutations = {
  updateRegion(state, { pid, listData }) {
    if (pid === '0') {
      state.provinceListData = listData;
    } else {
      state.provinceIDCityListData[pid] = listData;
    }
  }
};

const actions = {
  regionListData({ state, commit }, parent_id) {
    return new Promise((resolve, reject) => {
      if (parent_id === '0' && Object.keys(state.provinceListData).length > 0) {
        resolve();
      } else if (parent_id !== '0' && state.provinceIDCityListData[parent_id]) {
        resolve();
      } else {
        PostUploadRegion({ parent_id }).then(({ code, data, msg }) => {
          if (code === SUCCESS) {
            data && commit('updateRegion', { pid: parent_id, listData: data });
            resolve();
          } else {
            reject(msg);
          }
        }).catch(reject);
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};