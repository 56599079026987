// 无 layout 的路由
import { NoLayout } from '../constant-components.js';

export default {
  path: '/full-page',
  component: NoLayout,
  meta: {
    title: ''
  },
  children: [
    {
      path: 'teaching-preview',
      component: () => import('@/views/teaching/preview/preview.vue'),
      meta: {
        title: '资源预览'
      }
    },
    {
      path: 'paper-preview',
      component: () => import('@/views/paper/preview/preview.vue'),
      meta: {
        title: '试卷预览'
      }
    },
    {
      path: 'material-preview',
      component: () => import('@/views/material/preview/preview.vue'),
      meta: {
        title: '教材预览'
      }
    },
    {
      path: 'task-material-preview',
      component: () => import('@/views/material/task-preview/task-preview.vue'),
      meta: {
        title: '任务教材预览'
      }
    },
    {
      path: 'task-material-detail',
      component: () => import('@/views/material/task-detail/task-detail.vue'),
      meta: {
        title: '任务教材'
      }
    },
    {
      path: 'task-preview',
      component: () => import('@/views/material/preview/preview.vue'),
      meta: {
        title: '实训预览'
      }
    },
    {
      path: 'course-preview',
      component: () => import('@/views/material/preview/preview.vue'),
      meta: {
        title: '课程预览'
      }
    },
    {
      path: 'teaching-system-preview',
      component: () => import('@/views/course/teaching-system-preview/teaching-system-preview.vue'),
      meta: {
        title: '教学系统'
      }
    },
    {
      path: 'teaching-system-detail',
      component: () => import('@/views/course/teaching-system-detail/teaching-system-detail.vue'),
      meta: {
        title: '教学系统'
      }
    },
    {
      path: 'ad-details',
      component: () => import('@/views/banner/details.vue'),
      meta: {
        title: '广告详情'
      }
    },
    {
      path: 'problem-details',
      component: () => import('@/views/problems/details.vue'),
      meta: {
        title: '问题详情'
      }
    },
    {
      path: 'feedback-details',
      component: () => import('@/views/feedback/details.vue'),
      meta: {
        title: '离线留言详情'
      }
    },
    {
      path: 'knowledge-preview',
      component: () => import('@/views/knowledge/index/preview/preview.vue'),
      meta: {
        title: ' '
      }
    },

    {
      path: 'course-system-preview',
      component: () => import('@/views/course-system/preview/preview.vue'),
      meta: {
        title: '预览课程系统'
      }
    },
    {
      path: 'course-learning-preview',
      component: () => import('@/views/course-system/learning/learning.vue'),
      meta: {
        title: '课程学习预览'
      }
    },
    {
      path: 'course-system-view',
      component: () => import('@/views/course-system/view/view.vue'),
      meta: {
        title: '课程系统查看'
      }
    },
    {
      path: 'course-learning',
      component: () => import('@/views/course-system/learning/learning.vue'),
      meta: {
        title: '课程学习'
      }
    },

    {
      path: 'teaching-system-pre-view',
      component: () => import('@/views/teaching-system/preview/preview.vue'),
      meta: {
        title: '预览教学系统'
      }
    },
    {
      path: 'teaching-system-view',
      component: () => import('@/views/teaching-system/view/view.vue'),
      meta: {
        title: '教学系统查看'
      }
    },
    {
      path: 'teaching-learning',
      component: () => import('@/views/course-system/learning/learning.vue'),
      meta: {
        title: '任务学习'
      }
    },
    {
      path: 'resource-view',
      component: () => import('@/views/teaching-system/resource-view/resource-view.vue'),
      meta: {
        title: '资源查看'
      }
    },

    {
      path: 'training-system-preview',
      component: () => import('@/views/training-system/preview/preview.vue'),
      meta: {
        title: '预览实训系统'
      }
    },
    {
      path: 'training-system-view',
      component: () => import('@/views/training-system/view/view.vue'),
      meta: {
        title: '实训系统查看'
      }
    },

    {
      path: 'homework-view',
      component: () => import('@/views/paper/preview/preview.vue'),
      meta: {
        title: '作业查看'
      }
    },
    {
      path: 'exam-view',
      component: () => import('@/views/paper/preview/preview.vue'),
      meta: {
        title: '考试查看'
      }
    },

    {
      path: 'data-board',
      component: () => import('@/views/data-board/view/index.vue'),
      meta: {
        title: '大数据看板'
      }
    },
  ]
};