// 实训管理
import { Layout, NoLayout } from '../constant-components.js';

export default {
  path: '/product',
  redirect: '',
  component: Layout,
  meta: {
    title: ''
  },
  children: [
    {
      path: 'index',
      component: NoLayout,
      meta: {
        title: '产品库列表',
        associateMenu: 'product/index'
      },
      children: [
        {
          path: 'list',
          component: () => import('@/views/product/list/list.vue'),
          meta: {
            // title: '产品库列表'
          }
        },
        {
          path: 'add',
          component: () => import('@/views/product/update/update.vue'),
          meta: {
            title: '新增商品'
          }
        },
        {
          path: 'edit',
          component: () => import('@/views/product/update/update.vue'),
          meta: {
            title: '编辑商品'
          }
        },
        {
          path: 'view',
          component: () => import('@/views/product/view/view.vue'),
          meta: {
            title: '查看'
          }
        },
      ]
    }
  ]
};